<template>
  <!--注册流程-->
  <div class="select-box">
      <img style="width: 100%;" src="@/assets/images/zhaopin.png" />
<!--    <div class="buttons">-->
<!--      <van-button plain hairline type="primary" @click="go('select')">立即注册</van-button>-->
<!--      <van-button plain hairline type="primary" class="yq" @click="go('my-invite')">邀请用户</van-button>-->
<!--    </div>-->
  </div>
</template>


<script>
import store from '../../store'
export default {

  components: {
  },
  data() {
    return {
      index: 0,
      name:undefined,
      sex:undefined,
      phone:undefined,
    };
  },
  mounted() {
    // let user = JSON.parse(`${store.state.user}`)
    // console.info("load user",user)
    // if(user.phone){
    //   this.go("home")
    // }
  },
  methods: {
    go(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
  body{
    background: #F0F5FD;
  }
  .select-box{
    margin: auto;
    text-align: center;
  }
  .buttons{
    margin-top: -3px;
    height: 65px;
    background: #2b27b3;
    padding-top: 15px;
    .yq{
      margin-left: 20px;
    }
  }
</style>
<style>
.home .c-nav-bar{
  margin-bottom: 0px;
}
</style>